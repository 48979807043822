import { IconButton, Box, Typography } from '@mui/material'
import MailchimpForm from './MailchimpForm'
import InstagramIcon from '@mui/icons-material/Instagram'

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: 'rgba(0, 0, 0, 0.9)',
        backgroundColor: 'rgba(19, 66, 17, 0.96)',
        padding: '1rem',
        paddingBottom: '1.75rem',
        color: 'white',
        textAlign: 'center',
        width: 'calc(100% - 2rem)',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <MailchimpForm />
      {/* <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '0.25rem',
          color: 'rgba(256, 256, 256, 0.9)',
          width: 'fit-content'
        }}
        href="https://www.instagram.com/brainfuelcoffee/"
        target="_blank"
      >
        <InstagramIcon
          sx={{
            color: 'rgba(256, 256, 256, 0.9)',
            marginRight: '0.25rem'
          }}
        />
        <Typography component="div">brainfuelcoffee</Typography>
      </IconButton> */}
      <Box>
        <Typography
          component="div"
          sx={{
            fontSize: '13px'
          }}
        >
          © {new Date().getFullYear()} Copyright Brain Fuel Coffee LLC. Sparking
          creativity in every cup. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}
