import CoffeeIcon from '@mui/icons-material/Coffee'
import { Box, Typography } from '@mui/material'

export default function Purchase() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
      }}
    >
      <Typography variant="body1">
        All orders are roasted on Sunday and Wednesday then shipped out the
        following day. Depending on the chosen shipping method, you can expect
        your fresh coffee in 2-7 days.
      </Typography>
      <br />
      <CoffeeIcon sx={{ width: '150px', height: '150px' }} />
      <br />
      <Typography>
        For any additional questions or concerns please contact
        brainfuelcoffee@gmail.com
      </Typography>
    </Box>
  )
}
