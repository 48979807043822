const axios = require('axios')

export default function cancelSubscription(subscriptionId) {
  const config = {
    method: 'post',
    url: '/.netlify/functions/cancel-subscription',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ subscriptionId })
  }

  return axios(config)
}
