const axios = require('axios')

export default function stripeSubscriptions(customer_id, email) {
  const config = {
    method: 'post',
    url: '/.netlify/functions/stripe-subscriptions',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ customer_id, email })
  }

  return axios(config)
}
