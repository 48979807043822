import { useState } from 'react'
import Nav from '../components/Nav'
import LineTop from '../components/LineTop'
import Footer from '../components/Footer'
import Faq from '../components/Faq'
import {
  Box,
  Button,
  Card,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import sendMessage from '../requests/send-message'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

import Stone from '../images/stone.jpg'
const mintGreen = 'rgb(165 238 170 / 45%)'

const reasons = [
  {
    label: 'Wholesale',
    value: 'wholesale'
  },
  {
    label: 'Subscription',
    value: 'subscription'
  },
  {
    label: 'Order Status',
    value: 'order_status'
  },
  {
    label: 'Cancel Subscription',
    value: 'cancel_subscription'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

const formDefault = {
  reason: '',
  name: '',
  email: '',
  comments: ''
}

export default function ContactUs() {
  const [form, setForm] = useState(formDefault)
  const [formValid, setFormValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messageStatus, setMessageStatus] = useState(null)

  const handleChange = e => {
    const tempForm = structuredClone(form)
    tempForm[e.target.name] = e.target.value
    setForm(tempForm)
    setFormValid(!Object.values(tempForm).some(element => element === ''))
  }

  const handleMessage = () => {
    setLoading(true)
    sendMessage(form)
      .then(() => {
        setLoading(false)
        setMessageStatus('success')
        setForm(formDefault)
        setFormValid(false)
      })
      .catch(() => {
        setLoading(false)
        setMessageStatus('error')
        setForm(formDefault)
        setFormValid(false)
      })
  }

  const successMessage = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '1.5rem'
      }}
    >
      <CheckCircleIcon
        sx={{
          width: '4em',
          height: '4em'
        }}
      />
      <br />
      <Typography>Thank you for contacting us!</Typography>
      <br />
      <Typography>Your message was sent successfully!</Typography>
      <Typography>We will contact you shortly via email.</Typography>
    </Box>
  )

  const errorMessage = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '1.5rem'
      }}
    >
      <ErrorIcon
        sx={{
          width: '4em',
          height: '4em'
        }}
      />
      <br />
      <Typography>There was an issue sending your message!!</Typography>
      <br />
      <Typography>
        Please reach out to brainfuelcoffee@gmail.com with your inquiry
      </Typography>
    </Box>
  )

  return (
    <>
      <Nav />
      <Box
        component="main"
        sx={{
          backgroundImage: `url('${Stone}')`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: mintGreen,
            width: '100%',
            height: '100%'
          }}
        >
          {/* statement to contact */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: '0 1rem 1rem',
              maxWidth: '1000px',
              paddingTop: '2rem'
            }}
          >
            <Typography
              variant="h5"
              sx={{ borderBottom: '2px solid rgba(0, 0, 0, 0.8)' }}
            >
              Contact Brain Fuel Coffee LLC.
            </Typography>
            <br />
            <Typography variant="body1" paragraph>
              Should the need arise, please reach out to me by filling out the
              contact form below. Select a reason why you are reaching out and I
              will get back to you as soon as I am available to address your
              needs. Please also refer to some frequently asked questions that
              may be of use. Thank you!!
            </Typography>
          </Box>
          {/* contact form */}
          <Card
            sx={{
              margin: '0 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 30px - 2rem)',
              maxWidth: '425px',
              padding: '15px'
            }}
            elevation={12}
          >
            {messageStatus === 'success' && successMessage()}
            {messageStatus === 'error' && errorMessage()}
            {!messageStatus && (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    width: '100%',
                    marginBottom: '20px',
                    textAlign: 'center'
                  }}
                >
                  Contact Us
                </Typography>
                <TextField
                  className="contact-input"
                  id="reason"
                  select
                  label="Reason"
                  name="reason"
                  value={form.reason}
                  onChange={handleChange}
                  helperText="Please select the reason for your inquiry"
                  size="small"
                  sx={{ width: '100%', flexGrow: 1 }}
                  required
                >
                  {reasons.map(reason => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  className="contact-input"
                  id="name"
                  label="Name"
                  name="name"
                  type="text"
                  variant="outlined"
                  value={form.name}
                  onChange={handleChange}
                  size="small"
                  sx={{ width: '100%', flexGrow: 1 }}
                  required
                />
                <br />
                <TextField
                  className="contact-input"
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                  value={form.email}
                  onChange={handleChange}
                  size="small"
                  sx={{ width: '100%', flexGrow: 1 }}
                  required
                />
                <br />

                <TextField
                  className="contact-input"
                  id="comments"
                  multiline
                  minRows={4}
                  label="Comments"
                  name="comments"
                  variant="outlined"
                  value={form.comments}
                  onChange={handleChange}
                  size="small"
                  sx={{ width: '100%', flexGrow: 1 }}
                  required
                />
                <br />

                {loading && (
                  <LoadingButton loading variant="outlined">
                    Submit
                  </LoadingButton>
                )}
                {!loading && (
                  <Button
                    className="cart-checkout"
                    variant="contained"
                    sx={{
                      color: 'white',
                      padding: '5px 10px'
                    }}
                    size="small"
                    disabled={!formValid}
                    onClick={handleMessage}
                  >
                    Submit
                  </Button>
                )}
              </>
            )}
          </Card>
          {/* faq accordian */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 1rem 1rem',
              width: '1000px',
              maxWidth: 'calc(100% - 2rem)'
            }}
          >
            <Typography
              sx={{
                width: '100%',
                marginTop: '2rem',
                borderBottom: '2px solid rgba(0, 0, 0, 0.8)'
              }}
              variant="h5"
            >
              Frequently Asked Questions
            </Typography>
            <br />
            <Faq />
          </Box>
        </Box>
      </Box>
      <LineTop />
      <Footer />
    </>
  )
}
