import Box from '@mui/material/Box'

export default function LineTop() {
  return (
    <Box
      sx={{
        borderTop: '3px solid rgba(19, 66, 17, 1)',
        borderBottom: '3px solid transparent',
        width: '100%'
      }}
    />
  )
}
