import * as React from 'react'
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'

import Image1 from '../images/home/1.jpeg'
import Image2 from '../images/home/2.jpeg'
import Image3 from '../images/home/3.jpeg'
import Image4 from '../images/home/4.jpeg'
import Image5 from '../images/home/5.jpeg'
import Image6 from '../images/home/6.jpeg'
import Image7 from '../images/home/7.jpeg'

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`
  }
}

export default function QuiltedImageList({ columns, classes }) {
  const quiltWidth = classes === 'quilt-large' ? 1120 : 'unset'

  const rowHeight =
    classes === 'quilt-small' ? 175 : classes === 'quilt-medium' ? 163 : 160

  const itemData =
    classes === 'quilt-small'
      ? // 2x6
        itemDataSmall
      : classes === 'quilt-medium'
      ? // 4x3
        itemDataMedium
      : // 7x3
        itemDataLarge

  return (
    <ImageList
      className={classes}
      sx={{ width: quiltWidth, margin: 'unset' }}
      variant="quilted"
      cols={columns}
      rowHeight={rowHeight}
    >
      {itemData.map((item, index) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
          {index === 0 && (
            <ImageListItemBar
              title="brainfuelcoffee"
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label="info about brainfuelcoffee"
                  href="https://www.instagram.com/brainfuelcoffee/"
                  target="_blank"
                >
                  <InstagramIcon color="white" />
                </IconButton>
              }
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const itemDataLarge = [
  {
    img: Image5,
    title: 'campfire',
    cols: 2,
    rows: 3
  },
  {
    img: Image2,
    title: 'pulling a shot'
  },
  {
    img: Image3,
    title: 'fall coffee'
  },
  {
    img: Image4,
    title: 'joy in a cup',
    cols: 2,
    rows: 3
  },
  {
    img: Image1,
    title: 'handful of beans'
  },
  {
    img: Image6,
    title: 'fresh roast',
    cols: 2,
    rows: 2
  },
  {
    img: Image7,
    title: 'drip',
    rows: 2
  }
]

const itemDataMedium = [
  {
    img: Image5,
    title: 'bag of beans',
    cols: 2,
    rows: 3
  },
  {
    img: Image2,
    title: 'pulling a shot'
  },
  {
    img: Image3,
    title: 'fall coffee'
  },
  {
    img: Image6,
    title: 'iced latte',
    cols: 2,
    rows: 2
  }
]

const itemDataSmall = [
  {
    img: Image5,
    title: 'bag of beans',
    cols: 2,
    rows: 3
  },
  {
    img: Image2,
    title: 'pulling a shot'
  },
  {
    img: Image3,
    title: 'fall coffee'
  },
  {
    img: Image6,
    title: 'iced latte',
    cols: 2,
    rows: 2
  }
]
