const axios = require('axios')

export default function stripeCheckout(
  selections,
  mode,
  local,
  promotionalCodes,
  email
) {
  const config = {
    method: 'post',
    url: '/.netlify/functions/stripe-checkout',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ selections, mode, local, promotionalCodes, email })
  }

  return axios(config)
}
