const axios = require('axios')

export default function fetchProducts() {
  const config = {
    method: 'get',
    url: '/.netlify/functions/fetch-products',
    headers: {}
  }

  return axios(config)
}
