import { useMemo, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
const url =
  'https://brainfuelcoffeellc.us21.list-manage.com/subscribe/post?u=30a50f988ba4f3781bfd38a33&amp;id=b23871d42c&amp;f_id=009bd7e6f0'
// const testingUrl =
//   'https://gmail.us13.list-manage.com/subscribe/post?u=b9e6e631f68baae182b950f7e&amp;id=91feedb81d&amp;f_id=001467e5f0'

const MailchimpForm = () => {
  const [email, setEmail] = useState('')
  const [messageOverride, setMessageOverride] = useState(null)
  const [loading, setLoading] = useState(false)
  const formValid = useMemo(
    () => /(.+)@(.+){2,}\.(.+){2,}/.test(email),
    [email]
  )

  const handleStatus = status => {
    if (status === 'success' && !messageOverride) {
      setLoading(false)
      setMessageOverride('Thank you for subscribing to our newsletter!')
    } else if (status === 'error' && !messageOverride) {
      setLoading(false)
      setMessageOverride(
        'We ran into an error when signing you up for our newsletter. Please email brainfuelcoffee@gmail.com to subscribe!'
      )
    }
  }

  const handleSubscribe = async subscribe => {
    setLoading(true)
    await subscribe({ EMAIL: email })
  }

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => {
        handleStatus(status)
        return messageOverride ? (
          <Typography sx={{ margin: '10px 0' }}>{messageOverride}</Typography>
        ) : (
          <>
            <Typography>Subscribe to our Newsletter</Typography>
            <Box
              sx={{ display: 'flex', width: 'fit-content', margin: '10px 0' }}
            >
              <TextField
                className="contact-input subscribe-outline"
                id="email"
                label="Email"
                name="email"
                type="email"
                variant="filled"
                value={email}
                onChange={e => setEmail(e.target.value)}
                size="small"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '6px 0 0 6px',
                  '& input': {
                    fontSize: '14px'
                  }
                }}
                required
              />
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0 6px 6px 0',
                  display: 'flex'
                }}
              >
                {loading ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    className="cart-checkout"
                    sx={{
                      width: '110px',
                      borderRadius: '0 6px 6px 0'
                    }}
                  >
                    Subscribe
                  </LoadingButton>
                ) : (
                  <Button
                    className="cart-checkout"
                    variant="contained"
                    sx={{
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '0 6px 6px 0',
                      width: '110px'
                    }}
                    size="small"
                    disabled={!formValid}
                    onClick={() => handleSubscribe(subscribe)}
                  >
                    Subscribe
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )
      }}
    />
  )
}

export default MailchimpForm
