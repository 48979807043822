import Footer from '../components/Footer'
import Nav from '../components/Nav'
import Purchase from '../components/Purchase'
import Stone from '../images/stone.jpg'
import { Box, Card, CardContent, Typography } from '@mui/material'

const mintGreen = 'rgba(165, 238, 170, 0.45)'

const styles = {
  main: {
    backgroundImage: `url('${Stone}')`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    width: '100%'
  },
  backgroundLayer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: mintGreen,
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    margin: 'unset',
    padding: 'unset'
  },
  card: {
    width: '100%',
    maxWidth: '550px',
    margin: '1rem'
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    borderBottom: '0.05rem dashed black'
  },
  typographyWrapper: {
    textAlign: 'center'
  }
}

export default function PurchaseCompleted() {
  return (
    <>
      <Nav />
      <Box component="main" sx={styles.main}>
        <Box sx={styles.backgroundLayer}>
          <Box sx={styles.content}>
            <Card sx={styles.card} elevation={10}>
              <CardContent sx={styles.cardContentWrapper}>
                <CardContent sx={styles.cardContent}>
                  <Box sx={styles.typographyWrapper}>
                    <Typography variant="h5">
                      Thank You For Your Purchase!
                    </Typography>
                  </Box>
                </CardContent>
              </CardContent>
              <CardContent>{Purchase()}</CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
