import * as React from 'react'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PublicIcon from '@mui/icons-material/Public'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import InterestsIcon from '@mui/icons-material/Interests'
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'

const green = 'rgba(19, 66, 17, 1)'

const styles = {
  cardTop: {
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '64px'
    },
    iconButton: {
      width: '44px',
      height: '44px'
    },
    icon: {
      color: green,
      width: '24px',
      height: '24px'
    },
    price: {
      color: green,
      fontWeight: '600'
    }
  },
  cardBottom: {
    wrapper: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'end'
    },
    description: {
      display: 'flex',
      flexWrap: 'wrap',
      lineHeight: '1.15'
    },
    expand: {
      padding: '2px',
      marginLeft: '5px',
      height: 'fit-content',
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  },
  smallCard: {
    wrapper: {
      display: 'flex',
      width: '100%'
    },
    image: {
      width: '125px'
    }
  },
  mediumCard: {
    wrapper: {
      display: 'flex',
      width: '100%'
    },
    image: {
      width: '180px'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }
  },
  largeCard: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    image: {
      width: '290px',
      padding: '5px',
      borderRadius: '10px'
    }
  },
  cardBase: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 0.5rem 1rem 0.5rem !important',
      height: 'fit-content'
    },
    content: {
      width: '100%'
    }
  },
  collapse: {
    wrapper: {
      borderTop: '0.05rem solid rgba(0, 0, 0, 0.07)',
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
  },
  collapseRow: {
    wrapper: {
      display: 'flex',
      paddingBottom: '0.5rem',
      marginBottom: '0.5rem',
      borderBottom: '0.05rem dashed lightgray'
    },
    wrapperLast: {
      display: 'flex'
    },
    typography: {
      marginLeft: '1rem',
      alignSelf: 'center'
    }
  }
}

const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default function CoffeeCard({
  deg,
  id,
  title,
  description,
  detail,
  price,
  ounces,
  image,
  origin,
  process_note,
  flavor_notes,
  tips,
  roast_level
}) {
  const isExpandable = origin || process_note || flavor_notes || tips
  const { enqueueSnackbar } = useSnackbar()

  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const addToCart = () => {
    const cart = localStorage.getItem('BrainFuelCoffeeCart')
    const cartItems = JSON.parse(cart)
    const items = cartItems ? cartItems.slice() : []
    const selected = items.map(item => item.id).includes(id)
    if (selected) {
      const item = items.filter(item => item.id === id)[0]
      item.quantity++
    } else {
      items.push({ id, title, price, ounces, image, quantity: 1 })
    }
    localStorage.setItem('BrainFuelCoffeeCart', JSON.stringify(items))
    enqueueSnackbar(`${title} added to cart!`, { variant: 'success' })
  }

  const collapseRowData = [
    {
      text: origin,
      icon: <PublicIcon />
    },
    {
      text: process_note,
      icon: <BlurLinearIcon />
    },
    {
      text: `Flavor Notes - ${flavor_notes}`,
      icon: <InterestsIcon />
    },
    {
      text: `Roast Level - ${roast_level}`,
      icon: <SignalCellularAltIcon />
    },
    {
      text: tips,
      icon: <FreeBreakfastIcon />
    }
  ].filter(({ text }) => text)

  const cardContentTop = () => (
    <>
      <Box sx={styles.cardTop.wrapper}>
        <Typography component="div" variant="h5">
          {title}
        </Typography>
        <IconButton
          aria-label="settings"
          onClick={addToCart}
          sx={styles.cardTop.iconButton}
        >
          <AddShoppingCartIcon sx={styles.cardTop.icon} />
        </IconButton>
      </Box>
      <Typography variant="subtitle2" component="div" sx={styles.cardTop.price}>
        {`$${price} / ${ounces}oz.`}
      </Typography>
    </>
  )

  const cardContentBottom = () => (
    <Box sx={styles.cardBottom.wrapper}>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        component="div"
        sx={styles.cardBottom.description}
      >
        {description}
      </Typography>
      {isExpandable && (
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={styles.cardBottom.expand}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      )}
    </Box>
  )

  const buildImage = sx => (
    <CardMedia component="img" sx={sx} image={image} alt={title} />
  )

  const buildCardContentSmall = () => (
    <Box className="card-small" sx={styles.smallCard.wrapper}>
      {buildImage(styles.smallCard.image)}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        {cardContentTop()}
        {cardContentBottom()}
      </CardContent>
    </Box>
  )

  const buildCardContentMedium = () => (
    <Box className="card-medium" sx={styles.mediumCard.wrapper}>
      {buildImage(styles.mediumCard.image)}
      <CardContent sx={styles.mediumCard.content}>
        {cardContentTop()}
        {cardContentBottom()}
      </CardContent>
    </Box>
  )

  const buildCardContentLarge = () => (
    <Box
      className="card-large"
      sx={{
        ...styles.largeCard.wrapper,
        filter: title === 'Cosmos' ? `hue-rotate(${deg}deg)` : 'unset'
      }}
    >
      <CardContent>{cardContentTop()}</CardContent>
      {buildImage(styles.largeCard.image)}
      <CardContent>{cardContentBottom()}</CardContent>
    </Box>
  )

  const buildCollapseRows = () =>
    collapseRowData.map(({ text, icon }, index) => {
      const wrapper =
        index + 1 === collapseRowData.length
          ? styles.collapseRow.wrapperLast
          : styles.collapseRow.wrapper
      return (
        <Box key={`${id}Collapse${index}`} sx={wrapper}>
          {icon}
          <Typography variant="subtitle2" sx={styles.collapseRow.typography}>
            {text}
          </Typography>
        </Box>
      )
    })

  return (
    <Card sx={styles.cardBase.wrapper} elevation={12} className="product-card">
      <Box sx={styles.cardBase.content}>
        {buildCardContentSmall()}
        {buildCardContentMedium()}
        {buildCardContentLarge()}
        {isExpandable && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={styles.collapse.wrapper}>
              {buildCollapseRows()}
            </CardContent>
          </Collapse>
        )}
      </Box>
    </Card>
  )
}
