import React from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import './index.css'
import Home from './pages/Home'
import Products from './pages/Products'
import ContactUs from './pages/ContactUs'
import Cart from './pages/Cart'
import Cancel from './pages/Cancel'
import PurchaseCompleted from './pages/PurchaseCompleted'
import reportWebVitals from './reportWebVitals'
import { SnackbarProvider } from 'notistack'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider, Button } from '@mui/material'

const styles = {
  snackbarStyles: {
    color: 'black',
    border: '0.075rem solid black',
    outline: 'unset'
  },
  typography: {
    fontFamily: 'Abyssinica SIL',
    h4: {
      fontFamily: "'Galada', cursive",
      fontSize: '2.0rem'
    },
    h5: {
      fontFamily: 'Abyssinica SIL'
    },
    subtitle1: {
      fontFamily: 'Crimson Text'
    },
    subtitle2: {
      fontFamily: 'Josefin Sans'
    },
    body1: {
      fontFamily: 'Crimson Text'
    },
    body2: {
      fontFamily: 'Josefin Sans'
    },
    caption: {
      fontFamily: 'Josefin Sans'
    }
  }
}

const App = () => {
  const theme = createTheme({ typography: styles.typography })

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          action={() => (
            <Button
              variant="outlined"
              size="small"
              sx={styles.snackbarStyles}
              component={Link}
              to="/cart"
            >
              Checkout
            </Button>
          )}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/purchase-completed" element={<PurchaseCompleted />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
reportWebVitals()
