// testimonial images
import Steve from '../images/steve.jpeg'
import Jason from '../images/jason.jpeg'

import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, MobileStepper, Typography, Button } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const testimonials = [
  {
    name: 'Steve Robenalt',
    imgPath: Steve,
    text: `
      I've been drinking this coffee for awhile now and would highly recommend any of them.
    `
  },
  {
    name: 'Jason Mollerup',
    imgPath: Jason,
    text: `
      I bought Brain Fuel for cold brew and I love the sweet chocolatey finish that is has.
    `
  }
]

function Testimonials({
  width,
  flexDirection,
  textAlign,
  imageDimensions,
  buttonDisplay,
  justify
}) {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = testimonials.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    testimonials.length > 0 && (
      <Box sx={{ width, margin: 'auto' }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={7000}
          enableMouseEvents
        >
          {testimonials.map((step, index) => (
            <div key={step.name}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection,
                    margin: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign
                  }}
                >
                  <Box
                    sx={{
                      border: '3px solid rgba(19, 66, 17, 1)',
                      borderRadius: '100px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: imageDimensions,
                        width: imageDimensions,
                        borderRadius: '100px',
                        border: '3px solid white'
                      }}
                      src={step.imgPath}
                      alt={step.name}
                    />
                  </Box>
                  <Box
                    sx={{
                      margin: '1rem',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography sx={{ marginBottom: '0.5rem' }} variant="h5">
                      {step.name}
                    </Typography>
                    <Typography variant="body1">{step.text}</Typography>
                  </Box>
                </Box>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          sx={{ justifyContent: justify, backgroundColor: 'unset' }}
          className="testimonial-buttons"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              sx={{
                display: buttonDisplay,
                transform: 'translate(110px, -115px)',
                minWidth: '32px !important',
                minHeight: '32px !important',
                backgroundColor: 'rgba(19, 66, 17, 0.08)',
                borderRadius: '100px',
                padding: '3px !important'
              }}
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              sx={{
                display: buttonDisplay,
                transform: 'translate(-110px, -115px)',
                minWidth: '32px !important',
                minHeight: '32px !important',
                backgroundColor: 'rgba(19, 66, 17, 0.08)',
                borderRadius: '100px',
                padding: '3px !important'
              }}
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>
    )
  )
}

export default Testimonials
