import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import stripeSubscriptions from '../requests/stripe-subscriptions'
import cancelSubscription from '../requests/cancel-subscription'
import NotFound from '../components/NotFound'
import LoadingButton from '@mui/lab/LoadingButton'

import Stone from '../images/stone.jpg'
const mintGreen = 'rgb(165 238 170 / 45%)'

const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
})

const errorMessage = `
  There was an issue canceling your subscription.
  Please contact brainfuelcoffee@gmail.com.
`

const successMessage = `
  Your subscription was successfully canceled!
`

export default function Cancel() {
  const [searchParams] = useSearchParams()
  const [subscriptions, setSubscriptions] = useState([])
  const customer_id = searchParams.get('id')
  const email = searchParams.get('email')

  const [loading, setLoading] = useState('')
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    if (!customer_id || !email) {
      setFetching(false)
      return
    }
    stripeSubscriptions(customer_id, email)
      .then(res => {
        if (res.data) {
          setSubscriptions(res.data.subscriptions || [])
        }
        setFetching(false)
      })
      .catch(error => {
        setFetching(false)
        console.log(error)
      })
  }, [customer_id, email])

  const handleCancel = id => {
    setLoading(id)
    cancelSubscription(id)
      .then(response => {
        window.alert(successMessage)
        window.location.reload(false)
      })
      .catch(error => {
        setLoading('')
        window.alert(errorMessage)
      })
  }

  const buildSubItem = item => {
    const active = item.status === 'active'
    return (
      <CardContent
        key={item.id}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.05rem dashed black'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1">Subscription</Typography>
            <Typography
              sx={{
                backgroundColor: active ? mintGreen : 'lightgray',
                color: active ? 'darkgreen' : 'darkgray',
                borderRadius: '3px',
                padding: '2px 5px',
                fontSize: '12px',
                marginLeft: '10px',
                height: 'fit-content'
              }}
            >
              {item.status}
            </Typography>
          </Box>
          <Typography variant="caption">
            Started: {new Date(item.created).toLocaleDateString()}
          </Typography>
          <Typography variant="caption">
            Next Charge:{' '}
            {new Date(item.current_period_end).toLocaleDateString()}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">Items</Typography>
          <Typography variant="caption">{item.quantity}</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
        >
          <Typography variant="subtitle1">Price</Typography>
          <Typography variant="caption">
            {formatUSD.format(item.unit_amount / 100)}{' '}
          </Typography>
          {loading !== item.id && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{
                marginTop: '5px',
                fontSize: '9px',
                minWidth: 'unset'
              }}
              disabled={!active}
              onClick={() => handleCancel(item.id)}
            >
              Cancel
            </Button>
          )}
          {loading === item.id && (
            <LoadingButton
              loading
              size="small"
              variant="outlined"
              sx={{
                marginTop: '5px',
                fontSize: '9px',
                minWidth: 'unset'
              }}
            >
              Loading
            </LoadingButton>
          )}
        </Box>
      </CardContent>
    )
  }

  return (
    <>
      <Nav />
      <Box
        component="main"
        sx={{
          backgroundImage: `url('${Stone}')`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: mintGreen,
            width: '100%',
            height: '100%'
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              margin: 'unset',
              padding: 'unset'
            }}
          >
            <Card
              sx={{
                width: '100%',
                maxWidth: '550px',
                margin: '1rem'
              }}
              elevation={10}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">
                      Your Active Subscriptions
                    </Typography>
                  </Box>
                </CardContent>
                {subscriptions.length > 0 && (
                  <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                    <Typography variant="subtitle2">
                      {subscriptions.length} active subscription(s) found for{' '}
                      {searchParams.get('email')}
                    </Typography>
                  </CardContent>
                )}
                {subscriptions.length === 0 && !fetching && (
                  <CardContent>
                    <NotFound />
                  </CardContent>
                )}
                {subscriptions.length > 0 &&
                  subscriptions.map(subscription => buildSubItem(subscription))}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
