import './products.css'
import { useEffect, useState } from 'react'

import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Box from '@mui/material/Box'
import CoffeeCard from '../components/CoffeeCard'
import Typography from '@mui/material/Typography'

import fetchProducts from '../requests/fetch-products'
import LineTop from '../components/LineTop'

import Stone from '../images/stone.jpg'
const mintGreen = 'rgb(165 238 170 / 45%)'

export default function Products() {
  const [products, setProducts] = useState([])
  const [hueRotateDeg, setHueRotateDeg] = useState(0)

  const setHue = () => {
    setHueRotateDeg(hueRotateDeg >= 360 ? 0 : hueRotateDeg + 1)
  }

  useEffect(() => {
    const interval = setInterval(() => setHue(), 10)
    return () => clearInterval(interval)
  })

  const prodIsValid = ({ active, title, description, tips, price, ounces }) => {
    return active && !!title && (!!description || !!tips) && !!price && !!ounces
  }

  useEffect(() => {
    fetchProducts()
      .then(res => {
        const stripeProducts = res.data.products.data.map(product => {
          const {
            id,
            active,
            name: title,
            description,
            images,
            metadata: {
              price,
              ounces,
              origin,
              process: process_note,
              flavor_notes,
              tips,
              roast_level
            }
          } = product
          return {
            id,
            active,
            title,
            description,
            price,
            ounces,
            image: images[0],
            origin,
            process_note,
            flavor_notes,
            tips,
            roast_level
          }
        })
        setProducts(stripeProducts.filter(prod => prodIsValid(prod)).reverse())
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <Nav />
      <Box
        component="main"
        sx={{
          backgroundImage: `url('${Stone}')`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: mintGreen,
            width: '100%',
            height: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: '0 1rem 1rem',
              width: 'calc(100% - 2rem)',
              maxWidth: '1120px',
              paddingTop: '2rem'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.8)',
                width: '100%'
              }}
            >
              Products
            </Typography>
            {/* <br />
            <Typography variant="body1" paragraph>
              - product one-liner -
            </Typography> */}
          </Box>
          <Box className="productGrid">
            {products.map((product, index) => (
              <CoffeeCard
                deg={hueRotateDeg}
                key={`${product.title}${index}`}
                {...product}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <LineTop />
      <Footer />
    </>
  )
}
