import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: `0.05rem solid ${theme.palette.divider}`
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export default function Faq() {
  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Card elevation={12}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>How long does it take to ship my order?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <Typography>
            All orders are roasted on Sunday and Wednesday then shipped out the
            following day. Depending on the chosen shipping method, you can
            expect your fresh coffee in 2-7 days.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How do subscriptions work?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <Typography>
            In your cart (checkout), there is an option to subscribe to an
            order. You can choose to have shipments every two weeks or once a
            month. Your card will be automatically billed upon the date of the
            next shipment. Subscribers receive 10% off all orders!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Can I cancel my subscription?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <Typography>
            Absolutely! Select "Cancel Subscription" on this page and fill out
            the form. It will ask for your name, email, and any comments you may
            have. An email will be sent to confirm your cancelation. Follow the
            cancellation instructions to process your cancelation. You may also
            email brainfuelcoffee@gmail.com with any concerns you may have.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  )
}
