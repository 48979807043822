import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import LineBottom from '../components/LineBottom'
import LineTop from '../components/LineTop'
import Footer from '../components/Footer'
import { Box, Button, Typography } from '@mui/material'
import Testimonials from '../components/Testimonials'
import Stone from '../images/stone.jpg'

import QuiltedImageList from '../components/QuiltedImageList'
import AboutHero from '../components/AboutHero'

// const lightBrown = '#a99266'
const lightBrown = 'rgb(169 146 102 / 72%)'
const lightBrownHover = 'rgb(169 146 102 / 22%)'
const darkBrown = '#643e1b'
// const mintGreen = 'rgb(162 206 135 / 72%)'
const mintGreen = 'rgb(165 238 170 / 45%)'

export default function Home() {
  return (
    <>
      <Nav />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'snow'
        }}
        component="main"
      >
        {/* About */}
        <Box
          sx={{
            width: '100%',
            height: 'fit-content',
            backgroundImage: `url('${Stone}')`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed'
          }}
        >
          <Box
            sx={{
              backgroundColor: mintGreen,
              width: '100%',
              height: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                padding: '1rem'
              }}
            >
              {/* Text */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  maxWidth: '650px',
                  width: '100%'
                }}
              >
                <Typography
                  className="mobile"
                  variant="h4"
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '2.75rem',
                    filter:
                      'drop-shadow(2px 1.25px 0px rgba(19, 66, 17, 0.35))',
                    WebkitTextStroke: '0.035rem rgba(19, 66, 17, 1)'
                  }}
                >
                  Brain Fuel Coffee
                </Typography>
                <AboutHero classes="about-hero-mobile" margin="10px auto" />
                <Typography
                  className="desktop"
                  variant="h5"
                  sx={{
                    border: `3px solid ${darkBrown}`,
                    padding: '12px',
                    textAlign: 'center',
                    color: 'black',
                    backgroundColor: `${lightBrown}`,
                    outline: `0.05rem solid ${darkBrown}`,
                    outlineOffset: '-7px'
                  }}
                >
                  How It Began
                </Typography>
                <br />
                <Box
                  sx={{
                    textIndent: '1rem',
                    color: 'black'
                  }}
                >
                  <Typography variant="body1" paragraph>
                    Like many people, what first got me into coffee was the
                    sweet stuff that gave me a buzz. Then in 2019, something
                    changed when I was introduced to my first cup of "specialty
                    coffee."
                  </Typography>
                  <Typography variant="body1" paragraph>
                    It was a light roast coffee, but there was something unique
                    about it. It was sweet, bright, and fruity all at the same
                    time. This cup of coffee led me on a new discovery to taste
                    the different origins in which coffee grows.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    I was so fascinated how each origin, growing process, and
                    roasting process changed the flavor to reflect the bean that
                    I decided to roast my own coffee. Now I am finally ready to
                    share this experience with you all!
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '15px 0',
                      textIndent: '0'
                    }}
                  >
                    <Button
                      sx={{
                        color: 'black',
                        border: `1px solid ${darkBrown}`,
                        backgroundColor: lightBrown,
                        '&:hover': {
                          backgroundColor: lightBrownHover,
                          border: `1px solid ${darkBrown}`
                        }
                      }}
                      variant="outlined"
                      size="large"
                      component={Link}
                      to="/products"
                    >
                      Shop Now
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className="desktop" sx={{ width: '100px' }} />
              {/* Image */}
              <AboutHero classes="about-hero-desktop" margin="25px 10px" />
            </Box>
          </Box>
        </Box>
        <LineBottom />
        {/* Reviews / Testimonials */}
        <Box
          className="mobile-wide"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '30px 0'
          }}
        >
          <Testimonials
            width="375px"
            flexDirection="column"
            textAlign="center"
            imageDimensions="120px"
            buttonDisplay="none"
            justify="center"
          />
        </Box>
        <Box
          className="desktop-narrow"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: '30px 0'
          }}
        >
          <Testimonials
            width="650px"
            flexDirection="row"
            textAlign="left"
            imageDimensions="140px"
            buttonDisplay="none"
            justify="center"
          />
        </Box>
        <Box
          className="desktop"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: '120px 0'
          }}
        >
          <Testimonials
            width="650px"
            flexDirection="row"
            textAlign="left"
            imageDimensions="140px"
            buttonDisplay="inline-flex"
            justify="space-between"
          />
        </Box>
        {/* View Products Button */}
        <br />
        <br />
        <QuiltedImageList classes="quilt-small" columns={2} />
        <QuiltedImageList classes="quilt-medium" columns={4} />

        <Box
          className="quilt-large"
          sx={{
            outline: '3px solid rgba(19, 66, 17, 1)',
            outlineOffset: '3px'
          }}
        >
          <QuiltedImageList classes="quilt-large" columns={7} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '100px 0'
          }}
        >
          <Button
            sx={{
              color: 'black',
              border: `1px solid ${darkBrown}`,
              backgroundColor: lightBrown,
              '&:hover': {
                backgroundColor: lightBrownHover,
                border: `1px solid ${darkBrown}`
              }
            }}
            variant="outlined"
            size="large"
            component={Link}
            to="/products"
          >
            View Products
          </Button>
        </Box>
      </Box>
      <LineTop />
      <Footer />
    </>
  )
}
