import { Box, Card, IconButton, Typography } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import AboutHeroImage from '../images/about_hero.webp'

const styles = {
  card: {
    margin: '10px 10px 0',
    width: '500px',
    maxWidth: 'calc(100% - 22px)',
    borderRadius: '3px',
    border: '1px solid rgba(0, 0, 0, 0.1)'
  },
  instagram: {
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px',
      borderRadius: '3px'
    },
    icon: {
      marginRight: '0.25rem'
    },
    text: {
      fontStyle: 'italic'
    }
  }
}

export default function AboutHero({ classes, margin }) {
  return (
    <Card className={classes} sx={{ margin }} elevation={2}>
      <Box
        sx={styles.card}
        component="img"
        src={AboutHeroImage}
        alt="Brent making coffee"
      />
      <IconButton
        sx={styles.instagram.button}
        href="https://www.instagram.com/brainfuelcoffee/"
        target="_blank"
      >
        <InstagramIcon sx={styles.instagram.icon} />
        <Typography component="div" sx={styles.instagram.text}>
          brainfuelcoffee
        </Typography>
      </IconButton>
    </Card>
  )
}
