const axios = require('axios')

export default function sendMessage({ reason, name, email, comments }) {
  const config = {
    method: 'post',
    url: '/.netlify/functions/send-message',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ reason, name, email, comments })
  }

  return axios(config)
}
