import ErrorIcon from '@mui/icons-material/Error'
import { Box, Typography } from '@mui/material'

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
      }}
    >
      <ErrorIcon sx={{ width: '150px', height: '150px' }} />
      <br />
      <Typography variant="body1">
        No active subscriptions were found.
      </Typography>
      <br />
      <Typography>Having trouble canceling your subscription?</Typography>
      <Typography>Please contact brainfuelcoffee@gmail.com</Typography>
    </Box>
  )
}
