// React
import { useState } from 'react'
import { Link } from 'react-router-dom'

// Material UI Components
import {
  AppBar,
  // Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  CardContent
} from '@mui/material'

// Icons
import MenuIcon from '@mui/icons-material/Menu'
import CoffeeIcon from '@mui/icons-material/Coffee'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import ListIcon from '@mui/icons-material/List'

// Path
import { useLocation } from 'react-router-dom'

// Logo
// import BrainFuelLogo from "../images/logo.png";

const DRAWER_FROM = 'left'
const PAGES = [
  { text: 'Home', route: '/', icon: <CoffeeIcon /> },
  { text: 'Products', route: '/products', icon: <ListIcon /> },
  { text: 'Contact Us', route: '/contact-us', icon: <ContactSupportIcon /> },
  { text: 'Cart', route: '/cart', icon: <ShoppingCartIcon /> }
]

export default function ButtonAppBar() {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const path =
    location.pathname === '/'
      ? 'home'
      : location.pathname.slice(1, location.pathname.length)
  const toggleDrawer = () => setIsOpen(!isOpen)

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <CardContent sx={{ paddingBottom: 'unset' }}>
        <Box
          color="inherit"
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
          component={Link}
          to={'/'}
        >
          <Typography
            sx={{
              paddingBottom: '0.75rem',
              borderBottom: '0.05rem dashed gray',
              color: 'rgba(0, 0, 0, 0.7)'
            }}
            variant="h4"
          >
            Brain Fuel Coffee
          </Typography>
        </Box>
      </CardContent>
      <List>
        {PAGES.map(({ text, route, icon }, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={route}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const toolbarMobile = () => (
    <Toolbar key="toolbar-mobile" className="toolbar-mobile">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer}
        style={{ marginRight: 'unset' }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="div"
        variant="h4"
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem',
          flexGrow: 1,
          textTransform: 'capitalize'
        }}
      >
        {path.split('-').join(' ')}
      </Typography>
      <IconButton color="inherit" component={Link} to={'/cart'}>
        <CoffeeIcon color="white" sx={{ width: '1.5em', height: '1.5em' }} />
      </IconButton>
      {/* <Avatar alt="logo" src={BrainFuelLogo} /> */}
      <Drawer anchor={DRAWER_FROM} open={isOpen} onClose={toggleDrawer}>
        {list(DRAWER_FROM)}
      </Drawer>
    </Toolbar>
  )

  const toolbarDesktop = () => (
    <Toolbar key="toolbar-desktop" className="toolbar-desktop">
      <Box
        color="inherit"
        sx={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center'
        }}
        component={Link}
        to={'/'}
      >
        <CoffeeIcon color="white" sx={{ width: '1.25em', height: '1.25em' }} />
        {/* <Avatar alt="logo" src={BrainFuelLogo} /> */}
        <Typography
          variant="h4"
          sx={{
            textAlign: 'left',
            margin: '0 15px 0 25px',
            transform: 'translate(0, 4px)'
          }}
        >
          Brain Fuel Coffee
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', listStyleType: 'none' }} component="ul">
        {PAGES.map(page => (
          <Box className="link" component="li" key={page.route}>
            <Typography
              className={`link${
                location.pathname === page.route ? ' link--active' : ''
              }`}
              sx={{
                marginRight: '30px',
                color: 'white'
              }}
              component={Link}
              to={page.route}
            >
              {page.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Toolbar>
  )

  return (
    <>
      <AppBar
        position="fixed"
        elevation={4}
        sx={{
          backgroundColor: 'rgba(19, 66, 17, 0.96)',
          height: '64px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {toolbarMobile()}
        {toolbarDesktop()}
      </AppBar>
    </>
  )
}
