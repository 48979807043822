const axios = require('axios')

export default function validatePromo(promoCode) {
  const config = {
    method: 'post',
    url: '/.netlify/functions/validate-promo',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ promoCode })
  }

  return axios(config)
}
