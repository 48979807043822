import Nav from '../components/Nav'
import { useSnackbar } from 'notistack'
import Footer from '../components/Footer'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import stripeCheckout from '../requests/stripe-checkout'
import validatePromo from '../requests/validate-promo'
import Beans from '../images/beans-small.png'

const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
})

export default function Cart() {
  const { enqueueSnackbar } = useSnackbar()
  const [searchParams] = useSearchParams()
  const checkout_canceled = searchParams.get('checkout_canceled')
  if (checkout_canceled) {
    window.alert('Your checkout session was canceled')
    window.location.href = '/cart'
  }

  const cartItems = JSON.parse(
    localStorage.getItem('BrainFuelCoffeeCart') || '[]'
  )
  const filteredItems = cartItems
    .filter(item => item.quantity > 0)
    .map(filteredItem => {
      if (filteredItem.quantity > 5) {
        filteredItem.quantity = 5
      }
      return filteredItem
    })

  const [items, setItems] = useState(filteredItems)
  const itemsInCart = items.length > 0

  const [mode, setMode] = useState('payment')
  const [loading, setLoading] = useState(false)

  const [promo, setPromo] = useState('')
  const [promoCodesApplied, setPromoCodesApplied] = useState({})
  const [validating, setValidating] = useState(false)
  const [localPromo, setLocalPromo] = useState(false)

  const [email, setEmail] = useState('')

  const [amountOff, setAmountOff] = useState(0)
  const [percentOff, setPercentOff] = useState(0)
  const [bestCoupon, setBestCoupon] = useState({})

  const handlePromo = e => {
    setPromo(e.target.value.toUpperCase())
  }

  const handleEmail = e => setEmail(e.target.value)

  const addPromo = () => {
    if (promo.toUpperCase() === 'LOCAL') {
      setLocalPromo(true)
      enqueueSnackbar(`${promo} promo applied!`, {
        variant: 'success',
        action: () => {},
        className: 'promo-success'
      })
      setPromo('')
    } else {
      setValidating(true)
      validatePromo(promo)
        .then(res => {
          if (res.data.promo) {
            const tempCodes = structuredClone(promoCodesApplied)
            tempCodes[promo] = res.data.promo
            setPromoCodesApplied(tempCodes)
            pickBestPromo(tempCodes)
            enqueueSnackbar(`${promo} promo applied!`, {
              variant: 'success',
              action: () => {},
              className: 'promo-success'
            })
          } else {
            enqueueSnackbar(`${promo} is invalid!`, {
              variant: 'error',
              action: () => {},
              className: 'promo-error'
            })
          }
          setValidating(false)
          setPromo('')
        })
        .catch(err => {
          console.log(err)
          window.alert(
            'We could not validate your promo code. Please contact brainfuelcoffee@gmail.com for further assistance.'
          )
          setValidating(false)
          setPromo('')
        })
      setValidating(false)
      setPromo('')
    }
  }

  const handleCheckout = () => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/

    if (!emailRegex.test(email)) {
      window.alert('Please enter a valid email for checkout')
      return
    }

    const codes =
      Object.keys(bestCoupon).length === 0
        ? []
        : [{ promotion_code: bestCoupon.id }]
    setLoading(true)
    stripeCheckout(items, mode, localPromo, codes, email)
      .then(res => {
        setLoading(false)
        localStorage.setItem('BrainFuelCoffeeCart', JSON.stringify([]))
        window.location = res.data.session.url
      })
      .catch(err => {
        setLoading(false)
        let alert =
          'There was an issue checking out. Please contact brainfuelcoffee@gmail.com for further assistance.'
        if (err.response) {
          if (err.response.data.includes('This coupon')) {
            alert = err.response.data
          }
        }
        window.alert(alert)
        console.log(err)
      })
  }

  const totalPrice = items.reduce(
    (prev, { quantity, price }) => (prev += quantity * price),
    0
  )
  // HANDLES LOCAL PROMO
  const totalMinusPromo =
    totalPrice === 0 ? 0 : localPromo ? totalPrice : totalPrice + 7

  // HANDLES PERCENTAGE DISCOUNT
  // If promo percent off is greater than normal subscription discount
  // The promo is used else the normal 10% discount is used
  const percentDiscount =
    totalPrice === 0
      ? 0
      : totalPrice *
        (mode !== 'payment'
          ? percentOff / 100 > 0.1
            ? percentOff / 100
            : 0.1
          : percentOff / 100)

  // SHIPPING LINE ITEM
  const standardShipping = totalPrice === 0 ? 0 : 7

  // TOTAL MINUS DISCOUNTS
  const totalMinusDiscounts =
    totalPrice === 0 ? 0 : totalMinusPromo - percentDiscount - amountOff

  // ESTIMATED TAXES
  const taxes =
    totalPrice === 0 ? 0 : (totalMinusDiscounts - (localPromo ? 0 : 7)) * 0.078

  // CART TOTAL
  const cartTotal = totalPrice === 0 ? 0 : totalMinusDiscounts + taxes

  const frequencyText =
    mode === 'payment'
      ? '*one time purchase'
      : mode === 'monthly'
      ? '*once per month'
      : '*every two weeks'

  const handleChange = (id, plus) => {
    const tempItems = items.slice()
    tempItems.map(item => {
      if (
        item.id === id &&
        ((plus && item.quantity >= 0) || (!plus && item.quantity > 0))
      ) {
        plus ? item.quantity++ : item.quantity--
      } else if (item.quantity < 0) {
        item.quantity = 0
      }
      return item
    })
    setItems(tempItems)
    const filteredTempItems = tempItems.filter(item => item.quantity > 0)
    localStorage.setItem(
      'BrainFuelCoffeeCart',
      JSON.stringify(filteredTempItems)
    )
  }

  const pickBestPromo = tempPromoCodes => {
    const codes = Object.values(tempPromoCodes)
    const maxAmount = Math.max(
      ...codes.map(promo => promo.coupon.amount_off / 100 || 0)
    )
    const maxPercent = Math.max(
      ...codes.map(promo => promo.coupon.percent_off || 0)
    )
    const maxAmountForPercent = totalPrice * ((maxPercent || 0) / 100)
    const maxAmountIsApplied = (maxAmount || 0) > maxAmountForPercent
    const maxDiscount = {
      amountOff: maxAmountIsApplied ? maxAmount : 0,
      percentOff: maxAmountIsApplied ? 0 : maxPercent
    }
    console.log(maxDiscount)
    const couponApplied = codes.find(
      code =>
        code.coupon.amount_off / 100 === maxDiscount.amountOff ||
        code.coupon.percent_off === maxDiscount.percentOff
    )
    console.log(couponApplied)
    setAmountOff(maxDiscount.amountOff)
    setPercentOff(maxDiscount.percentOff)
    setBestCoupon(couponApplied)
  }

  const buildLineItems = () =>
    items.map(({ id, title, price, ounces, image, accent, quantity }) => (
      <Box
        key={`${id}${title}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.05rem dashed black'
        }}
      >
        <CardContent sx={{ display: 'flex', flexGrow: '1' }}>
          <CardMedia
            component="img"
            sx={{
              height: '85px',
              width: '68px',
              borderRadius: '3px',
              marginRight: '1rem'
            }}
            image={image}
            alt={title}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="subtitle2">{`${ounces}oz. bag`}</Typography>
            <Box>
              <ButtonGroup
                variant="contained"
                size="small"
                elevation={4}
                aria-label="outlined button group"
                className="small-buttons"
              >
                <Button
                  className="cart-quantity"
                  sx={{
                    backgroundColor: 'rgba(19, 66, 17, 1)',
                    border: 'unset !important'
                  }}
                  size="small"
                  onClick={() => handleChange(id, false)}
                  disabled={quantity === 0}
                >
                  -
                </Button>
                <Button
                  size="small"
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6) !important',
                    borderRight: 'unset !important',
                    borderLeft: 'unset !important',
                    borderTop: '0.05rem solid #643e1b !important',
                    borderBottom: '0.05rem solid #643e1b !important'
                  }}
                  disabled
                >
                  {quantity}
                </Button>
                <Button
                  className="cart-quantity"
                  sx={{
                    backgroundColor: 'rgba(19, 66, 17, 1)',
                    border: 'unset !important'
                  }}
                  size="small"
                  onClick={() => handleChange(id, true)}
                  disabled={quantity > 4}
                >
                  +
                </Button>
              </ButtonGroup>
              {quantity === 5 && (
                <Typography
                  sx={{
                    marginLeft: '10px',
                    fontStyle: 'italic',
                    fontSize: '10px'
                  }}
                  variant="caption"
                >
                  *max of 5
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'right'
          }}
        >
          <Typography variant="subtitle1">${price * quantity}</Typography>
          <Typography variant="subtitle2">${price}/each</Typography>
        </CardContent>
      </Box>
    ))

  return (
    <>
      <Nav />
      <Box
        component="main"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url('${Beans}')`,
          backgroundPosition: 'bottom',
          backgroundPositionX: 'left',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            margin: 'unset',
            padding: 'unset'
          }}
        >
          <Card
            sx={{
              width: '100%',
              maxWidth: '550px',
              margin: '1rem'
            }}
            elevation={10}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Checkout</Typography>
                </Box>
              </CardContent>
              <Box>{buildLineItems()}</Box>
              {itemsInCart && (
                <>
                  <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                    <Box>
                      <RadioGroup
                        className="subscription-radio-buttons"
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={mode}
                        onChange={e => setMode(e.target.value)}
                      >
                        <FormControlLabel
                          className="radio-button-subscription-label"
                          value="payment"
                          control={
                            <Radio
                              disabled={totalPrice === 0}
                              sx={{
                                '&.Mui-checked': {
                                  color: 'rgba(19, 66, 17, 1)'
                                },
                                '&.MuiRadio-root': {
                                  padding: '5px',
                                  margin: '0 4px'
                                }
                              }}
                            />
                          }
                          label="One Time Purchase"
                        />
                        <FormControlLabel
                          className="radio-button-subscription-label"
                          value="monthly"
                          control={
                            <Radio
                              disabled={totalPrice === 0}
                              sx={{
                                '&.Mui-checked': {
                                  color: 'rgba(19, 66, 17, 1)'
                                },
                                '&.MuiRadio-root': {
                                  padding: '5px',
                                  margin: '0 4px'
                                }
                              }}
                            />
                          }
                          label="Subscription - monthly (10% off)"
                        />
                        <FormControlLabel
                          className="radio-button-subscription-label"
                          value="bi-weekly"
                          control={
                            <Radio
                              disabled={totalPrice === 0}
                              sx={{
                                '&.Mui-checked': {
                                  color: 'rgba(19, 66, 17, 1)'
                                },
                                '&.MuiRadio-root': {
                                  padding: '5px',
                                  margin: '0 4px'
                                }
                              }}
                            />
                          }
                          label="Subscription - every 2 weeks (10% off)"
                        />
                      </RadioGroup>
                    </Box>
                  </CardContent>
                  <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography>Have a promo code?</Typography>
                      <ButtonGroup>
                        <TextField
                          className="contact-input"
                          id="promo"
                          label="Promo Code"
                          name="promo"
                          type="promo"
                          variant="outlined"
                          value={promo}
                          onChange={handlePromo}
                          size="small"
                          disabled={validating}
                        />
                        <Button
                          className="cart-checkout"
                          variant="contained"
                          sx={{
                            color: 'white',
                            padding: '5px',
                            fontSize: '1rem',
                            minWidth: '40px',
                            borderRadius: '100px',
                            marginLeft: '5px'
                          }}
                          size="small"
                          disabled={!promo || validating}
                          onClick={addPromo}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box
                      style={{
                        marginTop: '5px',
                        textAlign: 'right'
                      }}
                    >
                      {Object.keys(promoCodesApplied).length > 1 && (
                        <>
                          <Typography style={{ fontSize: 'x-small' }}>
                            You have multiple competing promo codes:{' '}
                            {Object.keys(promoCodesApplied).join(', ')}
                          </Typography>
                          <Typography style={{ fontSize: 'x-small' }}>
                            We've applied the one with the best discount for
                            you: {bestCoupon.code}
                          </Typography>
                        </>
                      )}
                      {Object.keys(promoCodesApplied).length === 1 && (
                        <Typography style={{ fontSize: 'x-small' }}>
                          Promo code applied:{' '}
                          {Object.keys(promoCodesApplied).join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                  <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography>Cart Subtotal:</Typography>
                      <Typography>
                        {`${formatUSD.format(totalPrice)}`}
                      </Typography>
                    </Box>
                    {percentDiscount + amountOff > 0 && (
                      <Box
                        sx={{
                          marginLeft: '20px',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography variant="subtitle2" fontSize="12px">
                          {mode !== 'payment'
                            ? percentOff > 10
                              ? `Subscription Promo (${percentOff}):`
                              : 'Subscription Discount:'
                            : `Promo Applied${
                                percentOff > 0 ? ` (${percentOff}% off):` : ':'
                              }`}
                        </Typography>
                        <Typography
                          sx={{
                            borderBottom: '0.01rem dashed lightgray',
                            margin: '0 5px',
                            transform: 'translate(0, -5px)',
                            flexGrow: 1
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          fontSize="12px"
                          color="darkred"
                        >
                          - {formatUSD.format(percentDiscount + amountOff)}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        marginLeft: '20px',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="subtitle2" fontSize="12px">
                        {localPromo
                          ? 'Free Shipping (LOCAL promo applied):'
                          : 'Standard Shipping:'}
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: '0.01rem dashed lightgray',
                          margin: '0 5px',
                          transform: 'translate(0, -5px)',
                          flexGrow: 1
                        }}
                      />
                      <Typography variant="subtitle2" fontSize="12px">
                        {formatUSD.format(localPromo ? 0 : standardShipping)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: '20px',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="subtitle2" fontSize="12px">
                        Extimated Taxes:
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: '0.01rem dashed lightgray',
                          margin: '0 5px',
                          transform: 'translate(0, -5px)',
                          flexGrow: 1
                        }}
                      />
                      <Typography variant="subtitle2" fontSize="12px">
                        {formatUSD.format(taxes)}
                      </Typography>
                    </Box>
                  </CardContent>
                  {/* CART TOTAL */}
                  <CardContent sx={{ borderBottom: '0.05rem dashed black' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography>Cart Total:</Typography>
                      <Box sx={{ textAlign: 'right' }}>
                        <Typography>
                          {`${formatUSD.format(cartTotal)}`}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '11px',
                            color: 'rgba(0, 0, 0, 0.7)'
                          }}
                        >
                          {frequencyText}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                  <CardContent
                    sx={{
                      borderBottom: '0.05rem dashed black',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <TextField
                      className="contact-input"
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      variant="outlined"
                      value={email}
                      onChange={handleEmail}
                      size="small"
                      disabled={validating}
                      required
                    />
                    <Typography variant="caption" fontStyle="italic">
                      * Email required for purchase
                    </Typography>
                  </CardContent>
                </>
              )}
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexGrow: 1,
                  padding: '1rem 0 0 !important'
                }}
              >
                {loading && itemsInCart && (
                  <LoadingButton loading variant="outlined">
                    Submit
                  </LoadingButton>
                )}
                {!loading && itemsInCart && (
                  <Button
                    className="cart-checkout"
                    variant="contained"
                    sx={{
                      color: 'white',
                      padding: '5px 10px'
                    }}
                    size="small"
                    disabled={totalPrice === 0}
                    onClick={handleCheckout}
                  >
                    Checkout
                  </Button>
                )}
                {!itemsInCart && (
                  <Box
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    <Typography>You have no items in your cart!</Typography>
                    <br />
                    <RemoveShoppingCartIcon fontSize="large" />
                    <br />
                    <br />
                    <Button
                      className="cart-checkout"
                      variant="contained"
                      sx={{
                        color: 'white',
                        padding: '5px 10px'
                      }}
                      size="small"
                      component={Link}
                      to="/products"
                    >
                      View Products
                    </Button>
                  </Box>
                )}
              </CardContent>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
